import logo from './logo.svg';
import './App.css';
import EnglishSlider from "./pages/EN/englishslider";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import ArabicSlider from "./pages/Arabic/arabicslider";

function App() {
  return (
    <div className="App">
    <BrowserRouter>
    <Routes>

    <Route exact path="/" element={<EnglishSlider/>} />
    <Route exact path="/arabic" element={<ArabicSlider/>} />
    </Routes>

    </BrowserRouter>
    </div>
  );
}

export default App;
