import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";

import "./arabicslider.css";

// import required modules
import { EffectCards } from "swiper";

export default function Slider() {
  return (
    <>
      <Swiper
        effect={"cards"}
        grabCursor={true}
        modules={[EffectCards]}
        className="mySwiper dimensions "
      >
        <SwiperSlide>
        <a target="blank" class="hover-cursor link-field maillink" href="mailto:ali@turkishexportcenter.com"></a>
        <a target="blank" class="hover-cursor link-field whatsapplink" href="https://wa.link/214bzw"></a>
        <a  class="hover-cursor link-field english" href="/"></a>

        </SwiperSlide>
        <SwiperSlide>
        <a target="blank" class="hover-cursor link-field maillink" href="mailto:ali@turkishexportcenter.com"></a>
        <a target="blank" class="hover-cursor link-field whatsapplink" href="https://wa.link/214bzw"></a>
        <a  class="hover-cursor link-field english" href="/"></a>
        </SwiperSlide>
        <SwiperSlide>
        <a target="blank" class="hover-cursor link-field maillink" href="mailto:ali@turkishexportcenter.com"></a>
        <a target="blank" class="hover-cursor link-field whatsapplink" href="https://wa.link/214bzw"></a>
        <a  class="hover-cursor link-field english" href="/"></a>
        </SwiperSlide>
        <SwiperSlide>
        <a target="blank" class="hover-cursor link-field rightmaillink" href="mailto:ali@turkishexportcenter.com"></a>
        <a target="blank" class="hover-cursor link-field rightwhatsapplink" href="https://wa.link/214bzw"></a>
        <a  class="hover-cursor link-field rightenglish" href="/"></a>
        </SwiperSlide>

      </Swiper>
    </>
  );
}
